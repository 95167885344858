import '../App.css';
import { Cloud, Html, Line, Scroll, ScrollControls, Sky, useScroll } from '@react-three/drei';
import { Mesh, MeshBasicMaterial, NoToneMapping, Shape, ShapeGeometry, Vector3 } from 'three';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowTrendDown, faArrowTrendUp, faChartArea, faChartBar, faChevronCircleRight, faChevronRight, faFlagUsa, faPlane, faQuoteLeft, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Suspense, useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';
import axios from 'axios';

//prenume={prenume} nume={nume} email={email} companie={companie}
export default function GetAccess(props) {

    const arrowDiv = useRef();
    const arrow = useRef();
    const text = useRef();

    //7278201260:AAHYezAYnQzkpaohcYtPIr7WCMOk9gRO-Tg
    //curl -X GET "https://api.telegram.org/bot7278201260:AAHYezAYnQzkpaohcYtPIr7WCMOk9gRO-Tg/getUpdates"

    const [message, setMessage] = useState('ewwcewcewecwec');
    const [response, setResponse] = useState('');

    const handleClick = async () => {
        if (props.type) {
            try {
                // Înlocuiește cu URL-ul endpoint-ului tău care interacționează cu botul
                const result = await axios.post('https://api.telegram.org/bot7278201260:AAHYezAYnQzkpaohcYtPIr7WCMOk9gRO-Tg/sendMessage', {
                    chat_id: '-4505317067',
                    text: `🔔\n\n Nume: ${props.nume}\n Prenume: ${props.prenume}\n Email: ${props.email}\n Companie: ${props.companie}\n`,
                });
                setResponse(result.data.ok ? 'Mesaj trimis!' : 'Eroare la trimiterea mesajului.');
            } catch (error) {
                console.error('Eroare:', error);
                setResponse('Eroare la trimiterea mesajului.');
            }
        }
        else {
            window.scrollTo({ top: 999999999, left: 0, behavior: 'smooth' });
        }
    };

    return (
        <>
            {props.windowWidth > 1060 ?
                (<div
                    onClick={() => { handleClick() }}
                    onMouseEnter={() => {
                        arrowDiv.current.style.width = '7vw';
                        text.current.style.marginLeft = '4vw';
                        arrow.current.style.animation = 'loopArrow 0.7s ease-in-out';
                    }}
                    onMouseLeave={() => {
                        arrowDiv.current.style.width = '5vw';
                        text.current.style.marginLeft = '2vw';
                        arrow.current.style.animation = 'none';

                    }}
                    style={{ position: 'relative', overflow: 'hidden', fontSize: props.fontSize, width: '100%', fontWeight: 600, textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%', backgroundColor: '#f9a600', borderRadius: '2vw', cursor: 'pointer', pointerEvents: "all" }}>
                    <div
                        ref={text}
                        onClick={(element) => {

                        }} style={{ position: 'relative', fontSize: props.fontSize, fontWeight: 500, marginLeft: '2vw', width: '100%', textAlign: 'start', display: 'flex', justifyContent: 'start', alignItems: 'center', height: '100%', transition: 'all ease-in-out 0.3s' }}>
                        Sunt interesat
                    </div>
                    <div style={{ position: 'relative', fontSize: props.fontSize, fontWeight: 600, marginRight: '2vw', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <div ref={arrowDiv} style={{ backgroundColor: "#192227", display: 'flex', width: '5vw', justifyContent: 'center', alignItems: 'center', borderRadius: '20vw', transition: 'all ease-in-out 0.3s' }}>
                            <FontAwesomeIcon ref={arrow} style={{ position: 'relative', top: '0.1vw', color: "#f9a600", height: '2vw', width: '2vw', padding: '1vw' }} icon={faArrowRight} />
                        </div>
                    </div>
                </div>) :
                (<div
                    onClick={() => { handleClick() }}
                    onMouseEnter={() => {
                        arrowDiv.current.style.width = '18vw';
                        text.current.style.marginLeft = '6vw';
                        arrow.current.style.animation = 'loopArrow 0.7s ease-in-out';
                    }}
                    onMouseLeave={() => {
                        arrowDiv.current.style.width = '14vw';
                        text.current.style.marginLeft = '4vw';
                        arrow.current.style.animation = 'none';

                    }}
                    style={{ position: 'relative', overflow: 'hidden', fontSize: props.fontSize, width: '80%', minHeight: '15vw', fontWeight: 600, textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%', backgroundColor: '#f9a600', borderRadius: '6vw', cursor: 'pointer', pointerEvents: "all" }}>
                    <div
                        ref={text}
                        onClick={(element) => {

                        }} style={{ position: 'relative', fontSize: props.fontSize, fontWeight: 500, marginLeft: '4vw', width: '100%', textAlign: 'start', display: 'flex', justifyContent: 'start', alignItems: 'center', height: '100%', transition: 'all ease-in-out 0.3s' }}>
                        Sunt interesat
                    </div>
                    <div style={{ position: 'relative', fontSize: props.fontSize, fontWeight: 600, marginRight: '2vw', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <div ref={arrowDiv} style={{ backgroundColor: "#192227", display: 'flex', width: '14vw', justifyContent: 'center', alignItems: 'center', borderRadius: '20vw', transition: 'all ease-in-out 0.3s' }}>
                            <FontAwesomeIcon ref={arrow} style={{ position: 'relative', top: '0.1vw', color: "#f9a600", height: '6vw', width: '6vw', padding: '1vw' }} icon={faArrowRight} />
                        </div>
                    </div>
                </div>)}

        </>
    );
}
