import '../App.css';
import { Cloud, Html, Line, Scroll, ScrollControls, Sky, useScroll } from '@react-three/drei';
import { Mesh, MeshBasicMaterial, NoToneMapping, Shape, ShapeGeometry, Vector3 } from 'three';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowTrendDown, faArrowTrendUp, faChartArea, faChartBar, faChevronCircleRight, faChevronRight, faFlagUsa, faPlane, faQuoteLeft, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Suspense, useEffect, useRef, useState } from 'react';

export default function Part1(props) {

    return (
        <>
            {
                props.windowWidth > 1060 ?
                    (<div style={{ backgroundColor: 'rgba(25, 34, 39,0.6)', borderRadius: '3vw', width: '50%', marginTop: '70vw', padding: '3vw', color: 'white', marginRight: '0rem' }}>
                        <FontAwesomeIcon style={{ position: 'relative', fontSize: '3vw', top: '-1vw' }} icon={faQuoteLeft} />
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', height: '100%', fontSize: '2vw', cursor: 'text' }}>
                            Îți deschidem noi perspective de afaceri, atât la nivel național, cât și european, oferindu-ți acces la informații actuale și istorice despre licitații. Cu ajutorul datelor noastre precise și al instrumentelor avansate de analiză a competitorilor, vei putea lua decizii strategice bine fundamentate, avand date despre: piețele cu cel mai mare potențial, ofertele câștigătoare, informații detaliate despre concurență, precum și istoricul acestora de licitare.
                        </div>
                        <div style={{ position: 'relative', fontSize: '1.5vw', display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '1vw', marginBottom: '-1vw', marginTop: '2.5vw' }}>
                            <img src='./SebastianSorica.png' style={{ height: '6vw', borderRadius: 200 }} />
                            <div style={{ position: 'relative', fontSize: '1.5vw' }}>
                                <div>Sebastian Sorica</div>
                                <div style={{ fontSize: '1.2vw' }}>Specialist achizitii publice</div>
                            </div>
                        </div>
                    </div>) :
                    (<div style={{ backgroundColor: 'rgba(25, 34, 39,0.6)', borderRadius: '10vw', width: '80%', marginTop: '230vw', padding: '5vw', color: 'white', marginRight: '0rem', position: 'relative' }}>
                        <FontAwesomeIcon style={{ position: 'relative', fontSize: '10vw', top: '-1vw' }} icon={faQuoteLeft} />
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', height: '100%', fontSize: '6vw', cursor: 'text', marginTop: '1rem ' }}>
                            Îți deschidem noi perspective de afaceri, atât la nivel național, cât și european, oferindu-ți acces la informații actuale și istorice despre licitații. Cu ajutorul datelor noastre precise și al instrumentelor avansate de analiză a competitorilor, vei putea lua decizii strategice bine fundamentate, avand date despre: piețele cu cel mai mare potențial, ofertele câștigătoare, informații detaliate despre concurență, precum și istoricul acestora de licitare.
                        </div>
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '5vw', marginBottom: '-1vw', marginTop: '6vw' }}>
                            <img src='./SebastianSorica.png' style={{ height: '18vw', borderRadius: 200 }} />
                            <div style={{ position: 'relative', fontSize: '5vw' }}>
                                <div>Sebastian Sorica</div>
                                <div style={{ fontSize: '4vw' }}>Specialist achizitii publice</div>
                            </div>
                        </div>
                    </div>)
            }

        </>
    );
}
