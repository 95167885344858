import './App.css';
import { Environment, Scroll, ScrollControls, Sky } from '@react-three/drei';
import { NoToneMapping } from 'three';
import { Canvas } from '@react-three/fiber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faChevronCircleRight, faChevronRight, faPlane, faQuoteLeft, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import CanvasContent from './CanvasContent';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import ContentFirstScreen from './FirstScreen/ContentFirstScreen';
import { ScrollProvider, useScrollContext, useScrollOffset } from './ScrollProvider';
import ContentSecondScreen from './SecondScreen/ContentSecondScreen';

function App() {

  function setVhProperties() {
    // Calculate 1% of the viewport height
    let vh = window.innerHeight * 0.01;

    // Set the value in the CSS custom properties
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--hundred-vh', `${vh * 100}px`);

    // Optionally, adjust the body's height as well if needed
    document.body.style.height = `${vh * 100}px`;
  }

  // Set the properties initially
  setVhProperties();

  // Update the properties on resize
  window.addEventListener('resize', setVhProperties);


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []); // The empty dependency array ensures this runs only once after the component mounts


  return (
    <>
      {
        windowWidth > 1060 ? (<>
          <div style={{ position: 'fixed', cursor: 'pointer', top: '1.5vw', left: 0, zIndex: 999, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <div onClick={() => {
              window.scrollTo({ top: 999999999, left: 0, behavior: 'smooth' });
            }} className='topBar' style={{ position: 'relative', fontSize: '1vw', paddingLeft: '2vw', minHeight: '2vw', maxHeight: '3vw', paddingRight: '2vw', overflow: 'hidden', zIndex: 999, fontWeight: 600, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(249, 166, 0, 1)', color: 'black', borderRadius: '10vw', gap: '2vw' }}>
              Lansăm platforma curând! Află mai multe despre noi și obține acces.
              <span style={{ position: 'relative', width: '1.5vw', height: '1.5vw', overflow: 'hidden', marginLeft: '-1vw', marginRight: '-1vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <FontAwesomeIcon style={{ position: 'relative', transform: 'rotate(-45deg)', animation: "arrowTopAndBottom ease-in-out 3s infinite" }} icon={faArrowRight} />
              </span>
            </div>
          </div>
          <ContentFirstScreen windowWidth={windowWidth} />
          <ContentSecondScreen windowWidth={windowWidth} />
        </>
        ) : (<>
          <div style={{ position: 'fixed', cursor: 'pointer', top: '-1vw', left: 0, zIndex: 999, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <div onClick={() => {
              window.scrollTo({ top: 999999999, left: 0, behavior: 'smooth' });
            }} className='topBar' style={{ position: 'relative', width: '100%', fontSize: '3.6vw', paddingLeft: '5vw', minHeight: '2vw', maxHeight: '8vw', paddingRight: '5vw', overflow: 'hidden', zIndex: 999, fontWeight: 600, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(249, 166, 0, 1)', color: 'black', borderBottomLeftRadius: '10vw', borderBottomRightRadius: '10vw', gap: '2vw' }}>
              Lansăm platforma curând! Obține acces azi.
              <span style={{ position: 'relative', width: '3vw', height: '3vw', overflow: 'hidden', marginLeft: '0vw', marginRight: '0vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <FontAwesomeIcon style={{ position: 'relative', transform: 'rotate(-45deg)', animation: "arrowTopAndBottom ease-in-out 3s infinite" }} icon={faArrowRight} />
              </span>
            </div>
          </div>

          <ContentFirstScreen windowWidth={windowWidth} />
          <ContentSecondScreen windowWidth={windowWidth} />
        </>
        )
      }
      <Canvas
        gl={{
          powerPreference: "high-performance",
        }}
        linear
        camera={{ position: [0, 0, 20], fov: 15 }}
        style={{ position: 'fixed', top: 0, left: 0, backgroundColor: '#192227' }}
      >
        <CanvasContent windowWidth={windowWidth} />
      </Canvas>
    </>
  );
}

export default App;
