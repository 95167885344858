import '../App.css';
import { Cloud, Sky } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { Suspense, useEffect, useRef, useState } from 'react';
import { Vector3 } from 'three';

export default function BackgroundClouds(props) {
    const cloud1 = useRef();
    const sky = useRef();
    // const [scrollOffset, setScrollOffset] = useState(0);

    // Handle scroll event
    const handleScroll = () => {
        const scrollY = window.scrollY;
        
        if (sky.current) {
            sky.current.material.uniforms.sunPosition.value = new Vector3(-3, 0.5+scrollY*0.0001, 0);
        }
    };

    // Attach scroll event listener
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // // Use frame to update positions based on scroll
    // useFrame((state) => {
    //     const elapsedTime = state.clock.getElapsedTime();
    //     const sinTime = Math.sin(elapsedTime);

    //     if (cloud1.current) {
    //         if (scrollOffset < 350) {
    //             const down = scrollOffset * -0.005;
    //             if (down > -2) {
    //                 cloud1.current.position.y = down + state.mouse.y * 0.1 + sinTime * 0.02;
    //             }
    //         }

    //         cloud1.current.position.x = state.mouse.x * 0.1 + sinTime * 0.033;
    //         cloud1.current.position.z = sinTime * 0.02;
    //     }

    //     if (sky.current) {
    //         sky.current.material.uniforms.sunPosition.value = new Vector3(3 * scrollOffset * 0.001, 0.5, 0);
    //     }
    // });

    return (
        <>
            {props.windowWidth > 1060 ? (
                <group position={[0, 0, 0]}>
                    <Sky ref={sky} sunPosition={[-3, 0.5, 0]} />
                    <Suspense fallback={null}>
                        <group ref={cloud1}>
                            <Cloud volume={2} scale={0.5} position={[-3, 0, 0]} color={"white"} opacity={0.4} />
                            <Cloud volume={1} scale={0.5} position={[2, -1, 0]} color={"white"} opacity={0.4} />
                        </group>
                    </Suspense>
                </group>
            ) : (
                <group position={[0, 0, 0]}>
                    <Sky ref={sky} sunPosition={[-3, 0.5, 0]} />
                    <Cloud volume={2} scale={0.5} position={[-3, 0, 0]} color={"white"} opacity={0.4} />
                    <Cloud volume={1} scale={0.5} position={[1, -2, 0]} color={"white"} opacity={0.4} />
                </group>
            )}
        </>
    );
}
