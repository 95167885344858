import '../App.css';
import { Cloud, Html, Line, Scroll, ScrollControls, Sky, useScroll } from '@react-three/drei';
import { Mesh, MeshBasicMaterial, NoToneMapping, Shape, ShapeGeometry, Vector3 } from 'three';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowTrendDown, faArrowTrendUp, faChartArea, faChartBar, faChevronCircleRight, faChevronRight, faFlagUsa, faPlane, faQuoteLeft, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Suspense, useEffect, useRef, useState } from 'react';
import Part1 from './Part1';
import Part2 from './Part2';
import Part3 from './Part3';
import Part4 from './Part4';
import { useScrollContext } from '../ScrollProvider'

export default function ContentSecondScreen(props) {

    return (
        <>
            <div style={{ position: 'relative', top: '0rem', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1rem', zIndex: 1 }}>
                <Part1 windowWidth={props.windowWidth} />
                <Part2 windowWidth={props.windowWidth} />
                <Part3 windowWidth={props.windowWidth}/>
                <Part4 windowWidth={props.windowWidth}/>
            </div>
        </>
    );
}
