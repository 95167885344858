import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../App.css';
import { useScroll } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import GetAccess from '../FirstScreen/GetAccess';


const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(date).toUpperCase();
};


export default function Part4(props) {
    const scroll = useScroll();

    const windowRef = useRef(null);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });


    const today = new Date();
    const formattedDate = formatDate(today);

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (windowRef.current) {
                const rect = windowRef.current.getBoundingClientRect();
                const centerX = rect.width / 2; // Center X of windowRef
                const centerY = rect.height / 2; // Center Y of windowRef

                const mouseXRelative = event.clientX - rect.left - centerX; // Mouse X relative to center
                const mouseYRelative = event.clientY - rect.top - centerY; // Mouse Y relative to center

                setMousePosition({ x: mouseXRelative, y: mouseYRelative });
            }
        };

        const element = windowRef.current;
        if (element) {
            element.addEventListener('mousemove', handleMouseMove);
        }

        // Cleanup on component unmount
        return () => {
            if (element) {
                element.removeEventListener('mousemove', handleMouseMove);
            }
        };
    }, []);

    const [prenume, setPrenume] = useState("");
    const [nume, setNume] = useState("");
    const [email, setEmail] = useState("");
    const [companie, setCompanie] = useState("");

    return (
        <>
            {props.windowWidth > 1060 ?
                (<div ref={windowRef} style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '85vw', marginTop: '10vw', marginBottom: '5vw', height: '30vw', transform: ` rotateY(${(mousePosition.x / -60)}deg) rotateX(${(mousePosition.y / 60)}deg)` }}>
                    <div style={{ position: 'relative', borderTopLeftRadius: '2vw', borderBottomLeftRadius: '2vw', width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: "#f9a600", height: '100%' }}>
                        <img style={{ height: '85%' }} src='barcode.webp' />
                    </div>
                    <div style={{ position: 'relative', width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: "#fdfdfd", height: '100%', paddingLeft: '2rem', paddingRight: '2rem', marginRight: '2.2vw', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                        <img style={{ position: 'absolute', height: '100%', right: '-1.1vw', transform: 'scaleX(-1)' }} src='./timbru.webp' />
                        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', backgroundColor: "#fdfdfd", height: '130%' }}>
                            <div style={{ fontSize: '3.5vw', fontWeight: 600, maxWidth: '40vw', lineHeight: '3.5vw', width: '70%' }}>Sa programam o intalnire</div>
                            <div style={{ position: 'relative', display: 'flex', justifyContent: 'end', alignItems: 'center', width: '30%' }}>
                                <div
                                    className="Sticker__Wrapper-sc-1iclugn-0 fVqxjM"
                                    style={{
                                        background: `radial-gradient(125% 125% at ${(mousePosition.x / 40)}% ${(mousePosition.y / 40)}%, rgb(42, 208, 202) 0%, rgb(255, 255, 254) 22.92%, rgb(255, 211, 255) 46.88%, rgb(214, 218, 255) 68.23%, rgb(191, 251, 233) 87.5%, rgb(154, 223, 255) 100%)`,
                                    }}
                                >
                                    <div style={{ position: 'relative', fontSize: '1vw', mixBlendMode: 'color-burn', fontWeight: 700, top: '2vw' }}>sfenders.</div>
                                    <div
                                        className="Sticker__Bg1-sc-1iclugn-2 bDWlMR"
                                        style={{
                                            background: 'conic-gradient(from -4.79795deg at 50% 50%, rgba(0, 0, 0, 0.35) -24.66deg, rgba(255, 255, 255, 0.35) 0.25deg, rgba(0, 0, 0, 0.35) 50.63deg, rgba(0, 0, 0, 0.35) 51.97deg, rgba(255, 255, 255, 0.35) 88.12deg, rgba(0, 0, 0, 0.35) 142.5deg, rgba(255, 255, 255, 0.35) 196.87deg, rgba(0, 0, 0, 0.35) 256.87deg, rgba(255, 255, 255, 0.35) 300deg, rgba(0, 0, 0, 0.35) 335.2deg, rgba(0, 0, 0, 0.35) 335.34deg, rgba(255, 255, 255, 0.35) 360.25deg)',
                                        }}
                                    />
                                </div>
                            </div>

                        </div>

                        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'ROW', backgroundColor: "#fdfdfd", height: '30%', fontWeight: 600, fontSize: '1.3vw', opacity: 0.5 }}>
                            RO <FontAwesomeIcon style={{ marginLeft: '1.2vw', marginRight: '1.2vw' }} icon={faPlane} /> UE
                        </div>

                        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: "#fdfdfd", height: '100%' }}>
                            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%', borderBottom: 'solid 1px black' }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%', fontWeight: 600, opacity: 0.5, fontSize: '0.8vw' }}>STATUS</div>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', fontWeight: 600, opacity: 0.5, fontSize: '0.8vw' }}>LOC</div>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%', fontWeight: 600, opacity: 0.5, fontSize: '0.8vw' }}>GRUP</div>
                            </div>

                            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%', height: '4rem' }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%', borderRight: 'solid 1px black', fontSize: '1.2vw', fontWeight: 600, height: '100%' }}>IMBARCARE</div>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', borderRight: 'solid 1px black', fontSize: '1.2vw', fontWeight: 600, height: '100%' }}>1A</div>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%', fontSize: '1.2vw', fontWeight: 600, height: '100%' }}>CLASA I</div>
                            </div>
                        </div>

                        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', backgroundColor: "#fdfdfd", height: '9vw', gap: '1rem', marginBottom: '1.5vw' }}>

                            <div style={{ position: 'relative', width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', backgroundColor: "#fdfdfd", height: '100%' }}>
                                <img style={{ height: '7vw' }} src='./qr.webp' />
                            </div>
                            <div style={{ position: 'relative', width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: "#fdfdfd", height: '100%', gap: '0.5vw' }}>
                                <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', backgroundColor: "#fdfdfd", height: '100%', fontSize: '1.1vw', fontWeight: 500 }}>
                                    sfenders. este o platformă avansată dedicată facilitării accesului la licitațiile publice, oferind date actualizate, analize predictive și rapoarte personalizate.
                                </div>
                                <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'start', flexDirection: 'column', backgroundColor: "#fdfdfd", height: '100%', fontSize: '0.8vw', fontWeight: 200, marginBottom: '0rem' }}>
                                    Vom utiliza informațiile pe care le furnizați în conformitate cu toate reglementările UE și RO privind protecția datelor (GDPR).
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ position: 'relative', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: '2vw', borderBottomRightRadius: '2vw', width: '35%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%', paddingRight: '2vw', paddingLeft: '1.2vw', backgroundColor: 'white' }}>
                        <img style={{ position: 'absolute', height: '100%', left: '-1vw' }} src='./timbru.webp' />
                        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', height: '20%', flexDirection: 'row' }}>
                            <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', height: '100%', fontSize: '1.3vw', fontWeight: 600 }}>
                                <div><span style={{ opacity: 0.5, fontSize: '1vw', marginRight: '0.2vw' }}>#</span>RNW4934</div>
                            </div>
                            <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'end', flexDirection: 'column', height: '100%', fontSize: '1.3vw', fontWeight: 600 }}>
                                <div><span style={{ opacity: 0.5, fontSize: '1vw', marginRight: '0.2vw' }}>DATE</span> {formattedDate}</div>
                            </div>
                        </div>

                        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', paddingBottom: '1.5vw', height: '100%' }}>
                            <input
                                type="text"
                                placeholder="Prenume"
                                onChange={(e) => { setPrenume(e.target.value) }}
                                style={{ border: 'none', outline: 'none', padding: '0', margin: '0', height: '20%', fontSize: '1.3vw', opacity: 0.8, fontWeight: 600, borderBottom: 'solid 1px #d1d1d1', width: '90%', paddingLeft: '5%', paddingRight: '5%' }}
                            />
                            <input
                                type="text"
                                placeholder="Nume"
                                onChange={(e) => { setNume(e.target.value) }}
                                style={{ border: 'none', outline: 'none', padding: '0', margin: '0', height: '20%', fontSize: '1.3vw', opacity: 0.8, fontWeight: 600, borderBottom: 'solid 1px #d1d1d1', width: '90%', paddingLeft: '5%', paddingRight: '5%' }}
                            />
                            <input
                                type="text"
                                placeholder="Email"
                                onChange={(e) => { setEmail(e.target.value) }}
                                style={{ border: 'none', outline: 'none', padding: '0', margin: '0', height: '20%', fontSize: '1.3vw', opacity: 0.8, fontWeight: 600, borderBottom: 'solid 1px #d1d1d1', width: '90%', paddingLeft: '5%', paddingRight: '5%' }}
                            />
                            <input
                                type="text"
                                placeholder="Companie"
                                onChange={(e) => { setCompanie(e.target.value) }}
                                style={{ border: 'none', outline: 'none', padding: '0', margin: '0', height: '20%', fontSize: '1.3vw', opacity: 0.8, fontWeight: 600, borderBottom: 'solid 1px #d1d1d1', width: '90%', paddingLeft: '5%', paddingRight: '5%' }}
                            />


                            <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', height: '30%', marginTop: '1.2vw' }}>
                                <GetAccess scroll={scroll} fontSize={'2vw'} windowWidth={props.windowWidth} type={true} prenume={prenume} nume={nume} email={email} companie={companie} />
                            </div>
                        </div>
                    </div>
                </div>) :
                (<div ref={windowRef} style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '90%', marginTop: '10vw', marginBottom: '10vw', transform: ` rotateY(${(mousePosition.x / -60)}deg) rotateX(${(mousePosition.y / 60)}deg)` }}>
                    <div style={{ position: 'relative', borderTopLeftRadius: '8vw', paddingTop: '3vw', paddingBottom: '3vw', transform: 'rotate(0deg)', borderTopRightRadius: '8vw', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: "#f9a600" }}>
                        <img style={{ width: '90%' }} src='horizontalBar.webp' />
                    </div>

                    <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <img style={{ position: 'absolute', width: '100%', bottom: '-1.1vw' }} src='./timbruHorizontal2.webp' />
                        <div style={{ position: 'relative', paddingTop: '4vw', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', backgroundColor: "#fdfdfd", height: '100%' }}>
                            <div style={{ position: 'relative', width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                                <div style={{ fontSize: '8.5vw', fontWeight: 500, maxWidth: '70vw', lineHeight: '9vw', width: '70%' }}>Sa programam o intalnire</div>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'end', alignItems: 'center', width: '30%' }}>
                                    <div
                                        className="Sticker__Wrapper-sc-1iclugn-0 fVqxjM-phone"
                                        style={{
                                            background: `radial-gradient(125% 125% at ${(mousePosition.x / 40)}% ${(mousePosition.y / 40)}%, rgb(42, 208, 202) 0%, rgb(255, 255, 254) 22.92%, rgb(255, 211, 255) 46.88%, rgb(214, 218, 255) 68.23%, rgb(191, 251, 233) 87.5%, rgb(154, 223, 255) 100%)`,
                                        }}
                                    >
                                        <div style={{ position: 'relative', fontSize: '2.8vw', mixBlendMode: 'color-burn', fontWeight: 700, top: '4vw' }}>sfenders.</div>
                                        <div
                                            className="Sticker__Bg1-sc-1iclugn-2 bDWlMR"
                                            style={{
                                                background: 'conic-gradient(from -4.79795deg at 50% 50%, rgba(0, 0, 0, 0.35) -24.66deg, rgba(255, 255, 255, 0.35) 0.25deg, rgba(0, 0, 0, 0.35) 50.63deg, rgba(0, 0, 0, 0.35) 51.97deg, rgba(255, 255, 255, 0.35) 88.12deg, rgba(0, 0, 0, 0.35) 142.5deg, rgba(255, 255, 255, 0.35) 196.87deg, rgba(0, 0, 0, 0.35) 256.87deg, rgba(255, 255, 255, 0.35) 300deg, rgba(0, 0, 0, 0.35) 335.2deg, rgba(0, 0, 0, 0.35) 335.34deg, rgba(255, 255, 255, 0.35) 360.25deg)',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ position: 'relative', paddingTop: '4vw', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'ROW', backgroundColor: "#fdfdfd", height: '30%', fontWeight: 600, fontSize: '4vw' }}>
                            <span style={{ opacity: 0.5, width: '90%', position: 'relative', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>RO <FontAwesomeIcon style={{ marginLeft: '3vw', marginRight: '3vw' }} icon={faPlane} /> UE</span>
                        </div>

                        <div style={{ position: 'relative', paddingTop: '4vw', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: "#fdfdfd", gap: '1rem', marginBottom: '1.5vw' }}>
                            <div style={{ position: 'relative', width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', backgroundColor: "#fdfdfd", height: '100%', fontSize: '4vw', fontWeight: 500 }}>
                                sfenders. este o platformă avansată dedicată facilitării accesului la licitațiile publice, oferind date actualizate, analize predictive și rapoarte personalizate.
                            </div>
                            <div style={{ position: 'relative', width: '90%', display: 'flex', justifyContent: 'end', alignItems: 'start', flexDirection: 'column', backgroundColor: "#fdfdfd", height: '100%', fontSize: '3vw', fontWeight: 200, marginBottom: '1rem' }}>
                                Vom utiliza informațiile pe care le furnizați în conformitate cu toate reglementările UE și RO privind protecția datelor (GDPR).
                            </div>
                        </div>
                    </div>

                    <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '2.5vw', backgroundColor: 'white', borderTopLeftRadius: '2vw', borderTopRightRadius: '2vw', borderBottomRightRadius: '6vw', borderBottomLeftRadius: '6vw' }}>
                        <img style={{ position: 'absolute', width: '100%', top: '-1.1vw' }} src='./timbruHorizontal1.webp' />
                        <div style={{ position: 'relative', width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: '3vw' }}>
                            <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', height: '100%', fontSize: '4vw', fontWeight: 600 }}>
                                <div><span style={{ opacity: 0.5, fontSize: '3vw', marginRight: '0.2vw' }}>#</span>RNW4934</div>
                            </div>
                            <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'end', flexDirection: 'column', height: '100%', fontSize: '4vw', fontWeight: 600 }}>
                                <div><span style={{ opacity: 0.5, fontSize: '3vw', marginRight: '0.2vw' }}>DATE</span> {formattedDate}</div>
                            </div>
                        </div>

                        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingBottom: '1.5vw', height: '100%', marginTop: '4vw' }}>

                            <input
                                type="text"
                                placeholder="Prenume"
                                onChange={(e) => { setPrenume(e.target.value) }}
                                style={{ border: 'none', outline: 'none', padding: '2vw', margin: '0', height: '20%', fontSize: '4vw', opacity: 0.8, fontWeight: 600, borderBottom: 'solid 1px #d1d1d1', width: '90%' }}
                            />
                            <input
                                type="text"
                                placeholder="Nume"
                                onChange={(e) => { setNume(e.target.value) }}
                                style={{ border: 'none', outline: 'none', padding: '2vw', margin: '0', height: '20%', fontSize: '4vw', opacity: 0.8, fontWeight: 600, borderBottom: 'solid 1px #d1d1d1', width: '90%' }}
                            />
                            <input
                                type="text"
                                placeholder="Email"
                                onChange={(e) => { setEmail(e.target.value) }}
                                style={{ border: 'none', outline: 'none', padding: '2vw', margin: '0', height: '20%', fontSize: '4vw', opacity: 0.8, fontWeight: 600, borderBottom: 'solid 1px #d1d1d1', width: '90%' }}
                            />
                            <input
                                type="text"
                                placeholder="Companie"
                                onChange={(e) => { setCompanie(e.target.value) }}
                                style={{ border: 'none', outline: 'none', padding: '2vw', margin: '0', height: '20%', fontSize: '4vw', opacity: 0.8, fontWeight: 600, borderBottom: 'solid 1px #d1d1d1', width: '90%' }}
                            />


                            <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '5vw', marginTop: '6vw' }}>
                                <GetAccess scroll={scroll} fontSize={'5vw'} windowWidth={props.windowWidth} type={true} prenume={prenume} nume={nume} email={email} companie={companie} />
                            </div>
                        </div>
                    </div>
                </div>)
            }
        </>
    );
}
