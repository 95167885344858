import '../App.css';
import { Cloud, Html, Line, Scroll, ScrollControls, Sky, useScroll } from '@react-three/drei';
import { Mesh, MeshBasicMaterial, NoToneMapping, Shape, ShapeGeometry, Vector3 } from 'three';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowTrendDown, faArrowTrendUp, faChartArea, faChartBar, faChevronCircleRight, faChevronRight, faFlagUsa, faPlane, faQuoteLeft, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Suspense, useCallback, useEffect, useRef, useState } from 'react';

export default function Part2(props) {
    const statsWindow = useRef();
    const line1 = useRef();
    const line2 = useRef();
    const line3 = useRef();
    const plane = useRef();
    const cargo = useRef();
    const ship = useRef();

    const setupStroke = useCallback((element) => {
        if (element && element.current) {
            const length = element.current.getTotalLength();
            element.current.style.strokeDasharray = length;
            element.current.style.strokeDashoffset = length;
        }
    }, []);

    const setupTransition = useCallback((element) => {
        if (element && element.current) {
            element.current.style.transition = 'stroke-dashoffset 0.5s ease-out';
        }
    }, []);


    useEffect(() => {
        if (props.windowWidth > 1060) {
            [line1, line2, line3, plane, cargo, ship].forEach((ref) => {
                setupStroke(ref);
                setupTransition(ref);
            });
        } else {
            setupStroke(line1);
            setupTransition(line1);
        }
    }, [props.windowWidth, setupStroke, setupTransition]);


    useEffect(() => {
        const handleScroll = () => {
            if (props.windowWidth > 1060) {
                const scrollOffset = window.scrollY / document.documentElement.scrollHeight; // Normalized scroll offset (0 to 1)
                const viewportHeight = window.innerHeight;
                const viewportWidth = window.innerWidth;

                if (statsWindow.current) {
                    let scaleFactor = Math.min(scrollOffset * viewportWidth * 0.25, 94);

                    // console.log("scrollOffset: ", scrollOffset, "scaleFactor: ", scaleFactor)
                    statsWindow.current.style.transform = `translateY(${60 - scaleFactor * 0.6}rem)`;
                    statsWindow.current.style.width = `${scaleFactor}vw`;
                }

                const updateStrokeOffset = (element, factor, lengthMultiplier = 1) => {
                    if (element && element.current) {
                        const length = element.current.getTotalLength() * lengthMultiplier;
                        const drawLength = scrollOffset * factor * length;
                        element.current.style.strokeDashoffset = Math.max(length - drawLength, 0);
                    }
                };

                updateStrokeOffset(line1, 2, 1.2);
                updateStrokeOffset(line2, 3.5, 1.3);
                updateStrokeOffset(line3, 2.2, 1.4);
                updateStrokeOffset(plane, 2.8, 1.7);
                updateStrokeOffset(cargo, 1, 1.5);
                updateStrokeOffset(ship, 1.2, 2);
            } else {
                const scrollOffset = window.scrollY / document.documentElement.scrollHeight; // Normalized scroll offset (0 to 1)

                const updateStrokeOffset = (element, factor, lengthMultiplier = 1) => {
                    if (element && element.current) {
                        const length = element.current.getTotalLength() * lengthMultiplier;
                        const drawLength = scrollOffset * factor * length;
                        element.current.style.strokeDashoffset = Math.max(length - drawLength, 0);
                    }
                };

                updateStrokeOffset(line1, 1.70, 1.2);

                statsWindow.current.style.transform = ``;
                statsWindow.current.style.width = `90vw`;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [props.windowWidth]);

    return (
        <>
            {
                props.windowWidth > 1060 ?
                    (<div ref={statsWindow} style={{ position: 'relative', backgroundColor: 'rgba(25, 34, 39,1)', overflow: 'hidden', borderRadius: '3vw', width: '90%', marginTop: '4vh', padding: '2vw', color: 'white', zIndex: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', zIndex: 1 }}>


                        <svg width="105%" style={{ position: 'absolute', top: '7vw', zIndex: 0 }} viewBox="0 -20 3098 3300" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3050 2039C2416.5 1446 2188.83 1991.5 2125.5 2186.5C2131.67 2198.33 2145.3 2225.4 2150.5 2239" stroke="#333333" strokeWidth="40" strokeLinecap="round" />
                            <path d="M3082.5 111.5C3077 111.5 2380.5 -250.999 2262.5 429.5C2272 454 2309 556.5 2300.5 672" stroke="#333333" strokeWidth="40" strokeLinecap="round" />
                            <path d="M15 22C15 22 1622.5 51.5 1820.5 58.5C2190.09 71.5664 2489 681.5 2161.38 928L1836.33 1166.24L1698.5 1258.5C1391 1470.35 1194 1262.5 1137.5 1569C1069.75 1936.52 1898.08 1868.16 2079.5 2115C2372 2510.5 2041.5 3006 1907 3158" stroke="#333333" strokeWidth="40" strokeLinecap="round" />

                            {/* <path d="M837 1743.5V1797.5L879.5 1818.5M837 1743.5L879.5 1722M837 1743.5L879.5 1766M879.5 1722V1707M879.5 1722V1766M879.5 1707L928.5 1684.01M879.5 1707L928.5 1734.5M1015.5 1687L969 1665L928.5 1684.01M1015.5 1687L977 1708.02M1015.5 1687V1743.5M928.5 1734.5V1788.75M928.5 1734.5L977 1708.02M928.5 1843L879.5 1818.5M928.5 1843V1788.75M928.5 1843L977 1818.5M879.5 1818.5V1766M879.5 1766L928.5 1788.75M928.5 1788.75L1015.5 1743.5M1015.5 1743.5V1797.5L977 1818.5M977 1818.5V1708.02M977 1708.02L928.5 1684.01" stroke="#333333" stroke-width="12" strokeLinecap="round" /> */}
                            {/* <path d="M2126.5 1204.21L2049 1163.21L2063.5 1148.71L2159.5 1169.71L2204.5 1127.21C2225.7 1121.21 2227.33 1139.04 2225.5 1148.71L2181 1191.71L2204.5 1288.21L2189 1302.21L2148.5 1225.21L2107 1266.71L2112 1292.21L2099.5 1302.21L2081.5 1270.71L2049 1251.71L2059.5 1240.71L2085 1244.71L2126.5 1204.21Z" stroke="#333333" stroke-width="12" strokeLinecap="round" /> */}
                            {/* <path d="M2343 2759.61C2344.6 2759.61 2357 2730.94 2363 2716.61L2343 2707.49M2343 2759.61C2337 2766.81 2312.17 2762.61 2300.5 2759.61C2276.9 2766.41 2262.67 2762.44 2258.5 2759.61M2343 2759.61C2351.5 2761.61 2365 2765.61 2375 2765.61M2258.5 2759.61C2256.5 2759.61 2243.33 2730.94 2237 2716.61L2258.5 2706.96M2258.5 2759.61C2250.1 2754.81 2236 2761.61 2230 2765.61M2258.5 2706.96L2300.5 2688.11L2343 2707.49M2258.5 2706.96C2258.83 2690.34 2262.7 2657.81 2275.5 2660.61H2326M2343 2707.49C2342.83 2691.86 2339.2 2660.61 2326 2660.61M2326 2660.61C2304.8 2600.61 2283.5 2636.94 2275.5 2662.61" stroke="#333333" stroke-width="12" /> */}
                            {/* <circle cx="1872" cy="3201" r="55" fill="#333333" /> */}
                        </svg>


                        <svg width="105%" style={{ position: 'absolute', top: '7vw', zIndex: 0 }} viewBox="0 -20 3098 3300" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path ref={line3} d="M3050 2039C2416.5 1446 2188.83 1991.5 2125.5 2186.5C2131.67 2198.33 2145.3 2225.4 2150.5 2239" stroke="#ffffff" strokeWidth="40" strokeLinecap="round" />
                            <path ref={line2} d="M3082.5 111.5C3077 111.5 2380.5 -250.999 2262.5 429.5C2272 454 2309 556.5 2300.5 672" stroke="#ffffff" strokeWidth="40" strokeLinecap="round" />
                            <circle cx="1890" cy="3181" r="55" fill="#F9A600" />

                            <path ref={line1} d="M15 22C15 22 1622.5 51.5 1820.5 58.5C2190.09 71.5664 2489 681.5 2161.38 928L1836.33 1166.24L1698.5 1258.5C1391 1470.35 1194 1262.5 1137.5 1569C1069.75 1936.52 1898.08 1868.16 2079.5 2115C2372 2510.5 2041.5 3006 1907 3158" stroke="#F9A600" strokeWidth="40" strokeLinecap="round" />

                            <path ref={cargo} d="M837 1743.5V1797.5L879.5 1818.5M837 1743.5L879.5 1722M837 1743.5L879.5 1766M879.5 1722V1707M879.5 1722V1766M879.5 1707L928.5 1684.01M879.5 1707L928.5 1734.5M1015.5 1687L969 1665L928.5 1684.01M1015.5 1687L977 1708.02M1015.5 1687V1743.5M928.5 1734.5V1788.75M928.5 1734.5L977 1708.02M928.5 1843L879.5 1818.5M928.5 1843V1788.75M928.5 1843L977 1818.5M879.5 1818.5V1766M879.5 1766L928.5 1788.75M928.5 1788.75L1015.5 1743.5M1015.5 1743.5V1797.5L977 1818.5M977 1818.5V1708.02M977 1708.02L928.5 1684.01" stroke="#F9A600" strokeWidth="12" strokeLinecap="round" />
                            <path ref={plane} d="M2126.5 1204.21L2049 1163.21L2063.5 1148.71L2159.5 1169.71L2204.5 1127.21C2225.7 1121.21 2227.33 1139.04 2225.5 1148.71L2181 1191.71L2204.5 1288.21L2189 1302.21L2148.5 1225.21L2107 1266.71L2112 1292.21L2099.5 1302.21L2081.5 1270.71L2049 1251.71L2059.5 1240.71L2085 1244.71L2126.5 1204.21Z" stroke="#F9A600" strokeWidth="6" strokeLinecap="round" />
                            <path ref={ship} d="M2343 2759.61C2344.6 2759.61 2357 2730.94 2363 2716.61L2343 2707.49M2343 2759.61C2337 2766.81 2312.17 2762.61 2300.5 2759.61C2276.9 2766.41 2262.67 2762.44 2258.5 2759.61M2343 2759.61C2351.5 2761.61 2365 2765.61 2375 2765.61M2258.5 2759.61C2256.5 2759.61 2243.33 2730.94 2237 2716.61L2258.5 2706.96M2258.5 2759.61C2250.1 2754.81 2236 2761.61 2230 2765.61M2258.5 2706.96L2300.5 2688.11L2343 2707.49M2258.5 2706.96C2258.83 2690.34 2262.7 2657.81 2275.5 2660.61H2326M2343 2707.49C2342.83 2691.86 2339.2 2660.61 2326 2660.61M2326 2660.61C2304.8 2600.61 2283.5 2636.94 2275.5 2662.61" stroke="#F9A600" strokeWidth="6" strokeLinecap="round" />
                        </svg>



                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'start', textAlign: 'start', paddingLeft: '6rem', width: '100%', marginTop: '8vw', flexDirection: 'column' }}>
                            <div style={{ position: 'relative', backgroundColor: "#f9a600", color: "#192227", height: "2.5vw", width: '2.5vw', padding: '1vw', borderRadius: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', top: '-3.5vw' }}><FontAwesomeIcon style={{ position: 'relative', fontSize: '2vw' }} icon={faArrowTrendUp} /></div>
                            <div style={{ fontSize: '6vw', fontWeight: 600, lineHeight: '5.5vw', maxWidth: '54vw' }}>Revolutionam interacțiunea cu licitațiile publice</div>
                            <div style={{ fontSize: '2vw', fontWeight: 400, maxWidth: '45vw', color: "#f9a600", lineHeight: '2.3vw', marginTop: '2vw' }}>Succesul în afaceri se bazează pe informație. Îți oferim informații esențiale, livrate la timp și structurate astfel încât să sprijine procesul tău decizional. Astfel, economisești timp prețios și te poziționezi înaintea competitorilor tăi.</div>
                        </div>


                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'start', textAlign: 'start', paddingLeft: '6rem', width: '100%', marginTop: '37vw', flexDirection: 'column' }}>
                            <div style={{ fontSize: '5vw', fontWeight: 600, lineHeight: '5vw', maxWidth: '45vw' }}>Construit pentru scalabilitate.</div>
                            <div style={{ fontSize: '1.5vw', fontWeight: 400, maxWidth: '45vw', color: "white", lineHeight: '2vw', marginTop: '1vw' }}>
                                Oferim consultanță specializată în achizitii publice pentru a te ajuta să accesezi piețele din orice țară a Uniunii Europene. Indiferent dacă ești la început de drum sau vrei să-ți extinzi afacerea, echipa noastră de experți te va ghida prin toate etapele necesare pentru a vinde cu succes pe piețele europene, asigurându-te că îndeplinești toate cerințele legale și că maximizezi potențialul ofertelor tale.
                            </div>
                        </div>


                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', fontSize: 30, lineHeight: '2.5rem', fontWeight: 600, marginTop: '20vw', marginBottom: '8vw', flexDirection: 'column', gap: '3rem' }}>
                            <div style={{ fontSize: '5vw', fontWeight: 600, lineHeight: '5vw', maxWidth: '70vw' }}>Vei accesa o piata de <span style={{ color: "#f9a600" }}><span className='color-ro' style={{ position: 'absolute', zIndex: -1 }}>x120</span>x120</span> mai mare</div>
                            <div style={{ fontSize: '2vw', fontWeight: 400, maxWidth: '45vw', color: "white", lineHeight: '2.5vw' }}>
                                România deține doar <span style={{ color: "#f9a600" }}>0,8%</span> din volumul total al achizițiilor publice din Europa (30 miliarde comparativ cu 2 trilioane).
                            </div>
                        </div>
                    </div>) :
                    (<div ref={statsWindow} style={{ position: 'relative', backgroundColor: 'rgba(25, 34, 39,1)', overflow: 'hidden', borderRadius: '8vw', width: '90%', marginTop: '4vh', padding: '2vw', color: 'white', zIndex: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', zIndex: 1 }}>


                        <svg width="450%" style={{ position: 'absolute', left: '-200vw', top: '0vw', zIndex: 0 }} viewBox="0 -20 3098 3300" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 22C15 22 1622.5 51.5 1820.5 58.5C2190.09 71.5664 2489 681.5 2161.38 928L1836.33 1166.24L1698.5 1258.5C1391 1470.35 1194 1262.5 1137.5 1569C1069.75 1936.52 1898.08 1868.16 2079.5 2115C2372 2510.5 2041.5 3006 1907 3158" stroke="#333333" strokeWidth="40" strokeLinecap="round" />
                        </svg>


                        <svg width="450%" style={{ position: 'absolute', left: '-200vw', top: '0vw', zIndex: 0 }} viewBox="0 -20 3098 3300" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path ref={line1} d="M15 22C15 22 1622.5 51.5 1820.5 58.5C2190.09 71.5664 2489 681.5 2161.38 928L1836.33 1166.24L1698.5 1258.5C1391 1470.35 1194 1262.5 1137.5 1569C1069.75 1936.52 1898.08 1868.16 2079.5 2115C2372 2510.5 2041.5 3006 1907 3158" stroke="#F9A600" strokeWidth="40" strokeLinecap="round" />
                        </svg>



                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'start', textAlign: 'start', paddingLeft: '4rem', width: '100%', marginTop: '25vw', flexDirection: 'column' }}>
                            <div style={{ position: 'relative', backgroundColor: "#f9a600", color: "#192227", height: "8vw", width: '8vw', padding: '1vw', borderRadius: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', top: '-3.5vw' }}><FontAwesomeIcon style={{ position: 'relative', fontSize: '5vw' }} icon={faArrowTrendUp} /></div>
                            <div style={{ fontSize: '11vw', fontWeight: 600, lineHeight: '11vw', maxWidth: '80%' }}>Revolutionam interacțiunea cu licitațiile publice</div>
                            <div style={{ fontSize: '4.5vw', fontWeight: 400, color: "#f9a600", lineHeight: '6.5vw', marginTop: '5vw', maxWidth: '80%' }}>Succesul în afaceri se bazează pe informație. Îți oferim informații esențiale, livrate la timp și structurate astfel încât să sprijine procesul tău decizional. Astfel, economisești timp prețios și te poziționezi înaintea competitorilor tăi.</div>
                        </div>


                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'end', textAlign: 'end', paddingRight: '4rem', width: '100%', marginTop: '60vw', flexDirection: 'column' }}>
                            <div style={{ fontSize: '10vw', fontWeight: 600, lineHeight: '10vw' }}>Scalabilitate.</div>
                            <div style={{ fontSize: '4.5vw', fontWeight: 400, maxWidth: '80%', color: "white", lineHeight: '6vw', marginTop: '2vw' }}>
                                Indiferent dacă ești la început de drum sau vrei să-ți extinzi afacerea, echipa noastră te va ghida prin toate etapele necesare vanzarii cu succes pe piețele europene, asigurând maximizarea ofertelor tale.
                            </div>
                        </div>


                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', fontWeight: 600, marginTop: '47vw', marginBottom: '8vw', flexDirection: 'column', gap: '3rem' }}>
                            <div style={{ fontSize: '10vw', fontWeight: 600, lineHeight: '11vw', maxWidth: '70vw' }}>
                                Vei accesa o piata de <span style={{ color: "#f9a600" }}><span className='color-ro' style={{ position: 'absolute', zIndex: -1 }}>x120</span>x120</span> mai mare
                            </div>
                            <div style={{ fontSize: '4.5vw', fontWeight: 400, maxWidth: '80%', color: "white", lineHeight: '7vw' }}>
                                România deține doar <span style={{ color: "#f9a600" }}>0,8%</span> din volumul total al achizițiilor publice din Europa (30 miliarde comparativ cu 2 trilioane).
                            </div>
                        </div>
                    </div>)
            }

        </>
    );
}
