// CanvasContent.js
import { Html, Scroll, ScrollControls, useScroll } from '@react-three/drei';
import React, { useEffect } from 'react';
import BackgroundClouds from './SecondScreen/BackgroundClouds';
import ContentSecondScreen from './SecondScreen/ContentSecondScreen';
import { useFrame, useThree } from '@react-three/fiber';
import ContentFirstScreen from './FirstScreen/ContentFirstScreen';
import { ScrollProvider } from './ScrollProvider';


export default function CanvasContent(props) {
    const { viewport } = useThree();

    // Adjust this value to control how much content scrolls in relation to the viewport height
    const pages = viewport.width * 0.765;
    const MemoizedBackgroundClouds = React.memo(BackgroundClouds);

    return (
        <>
            <BackgroundClouds windowWidth={props.windowWidth}/>
            <ambientLight intensity={10} color={"white"} />
        </>
    );
}
