import '../App.css';
import { useRef, useState, useEffect } from 'react';

export default function Part3(props) {
    const windowRef = useRef();
    const [displayedText, setDisplayedText] = useState([]);

    // The text that you want to reveal on scroll with formatting
    const fullTextRo = [
        {
            type: 'paragraph',
            content: (
                <>
                    <strong>Ungaria:</strong> Peste <span style={{ color: '#FFA500' }}>85%</span> din licitațiile publice din Ungaria sunt de tip 'deschis' și peste <span style={{ color: '#FFA500' }}>60%</span> din acestea au fost câștigate de IMM-uri cu sub 249 angajați, avantajul fiind lipsa firmelor mari care au monopol.
                </>
            ),
        },
        {
            type: 'paragraph',
            content: (
                <>
                    <strong>Bulgaria:</strong> <span style={{ color: '#FFA500' }}>34%</span> din licitațiile de anul trecut au fost câștigate cu un singur participant, lăsând loc considerabil de a intra pe această piață.
                </>
            ),
        },
        {
            type: 'paragraph',
            content: (
                <>
                    <strong>Serbia:</strong> În 2020, Serbia a schimbat sistemul de licitații publice într-unul foarte transparent, inovativ și deschis. În Serbia, peste <span style={{ color: '#FFA500' }}>27%</span> din licitații au fost câștigate cu un singur licitator, creând o oportunitate pentru România să acapareze această piață.
                </>
            ),
        },
    ];
    
    const fullText = [
        {
            type: 'paragraph',
            content: (
                <>
                    <strong>Hungary:</strong> Over <span style={{ color: '#FFA500' }}>85%</span> of public tenders in Hungary are open-type, and over <span style={{ color: '#FFA500' }}>60%</span> of these have been won by SMEs with fewer than 249 employees, benefiting from the absence of large companies with monopolies.
                </>
            ),
        },
        {
            type: 'paragraph',
            content: (
                <>
                    <strong>Bulgaria:</strong> <span style={{ color: '#FFA500' }}>34%</span> of last year's tenders were won with a single participant, leaving a lot of room to enter this market.
                </>
            ),
        },
        {
            type: 'paragraph',
            content: (
                <>
                    <strong>Serbia:</strong> In 2020, Serbia changed its public tender system to a highly transparent, innovative, and open one. In Serbia, over <span style={{ color: '#FFA500' }}>27%</span> of tenders were won with a single bidder, creating an opportunity for Romania to capture this market.
                </>
            ),
        },
    ];

    useEffect(() => {
        const handleScroll = () => {
            if (props.windowWidth > 1060) {

                const scrollOffset = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight); // Normalize scroll offset between 0 and 1

                const totalChars = fullTextRo.reduce((acc, item) =>
                    acc + item.content.props.children.reduce((a, c) =>
                        a + (typeof c === 'string' ? c.length : 0), 0), 0); // Total number of characters

                if (windowRef.current) {
                    const charsToShow = Math.floor((scrollOffset - 0.65) * 4 * totalChars);
                    let cumulativeChars = 0;
                    const textToShow = [];

                    fullTextRo.forEach((item, index) => {
                        const paragraphText = [];
                        const children = item.content.props.children;

                        children.forEach((child, childIndex) => {
                            if (typeof child === 'string') {
                                const remainingChars = charsToShow - cumulativeChars;
                                if (remainingChars > 0) {
                                    paragraphText.push(
                                        child.substring(0, Math.min(remainingChars, child.length))
                                    );
                                    cumulativeChars += child.length;
                                }
                            } else {
                                // Handle non-string children (e.g., React elements)
                                if (cumulativeChars < charsToShow) {
                                    paragraphText.push(child);
                                }
                            }
                        });

                        if (paragraphText.length > 0) {
                            textToShow.push(
                                <p key={index}>
                                    {paragraphText}
                                </p>
                            );
                        }
                    });

                    setDisplayedText(textToShow);

                    // Adjust translateY and opacity
                    if (scrollOffset > 0.55) {
                        const opacity = (scrollOffset - 0.6) * 15;
                        windowRef.current.style.opacity = Math.min(opacity, 1); // Clamp opacity between 0 and 1
                    }
                }
            } else {

                const scrollOffset = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight); // Normalize scroll offset between 0 and 1

                const totalChars = fullTextRo.reduce((acc, item) =>
                    acc + item.content.props.children.reduce((a, c) =>
                        a + (typeof c === 'string' ? c.length : 0), 0), 0); // Total number of characters

                if (windowRef.current) {
                    const charsToShow = Math.floor((scrollOffset - 0.67) * 6 * totalChars);
                    let cumulativeChars = 0;
                    const textToShow = [];

                    fullTextRo.forEach((item, index) => {
                        const paragraphText = [];
                        const children = item.content.props.children;

                        children.forEach((child, childIndex) => {
                            if (typeof child === 'string') {
                                const remainingChars = charsToShow - cumulativeChars;
                                if (remainingChars > 0) {
                                    paragraphText.push(
                                        child.substring(0, Math.min(remainingChars, child.length))
                                    );
                                    cumulativeChars += child.length;
                                }
                            } else {
                                // Handle non-string children (e.g., React elements)
                                if (cumulativeChars < charsToShow) {
                                    paragraphText.push(child);
                                }
                            }
                        });

                        if (paragraphText.length > 0) {
                            textToShow.push(
                                <p key={index}>
                                    {paragraphText}
                                </p>
                            );
                        }
                    });

                    setDisplayedText(textToShow);

                    // Adjust translateY and opacity
                    if (scrollOffset > 0.55) {
                        const opacity = (scrollOffset - 0.6) * 15;
                        windowRef.current.style.opacity = Math.min(opacity, 1); // Clamp opacity between 0 and 1
                    }
                }
            }
        };

        // Attach scroll event listener
        window.addEventListener('scroll', handleScroll);

        return () => {
            // Clean up the event listener on component unmount
            window.removeEventListener('scroll', handleScroll);
        };
    }, [fullText]);


    return (
        <>
            {props.windowWidth > 1060 ?
                (
                    <div style={{ position: 'relative', height: '120vw' }}>

                        <div
                            ref={windowRef}
                            style={{
                                top: '10vw',
                                position: 'sticky',
                                backgroundColor: 'rgba(25, 34, 39,0.5)',
                                borderRadius: '4vw',
                                marginTop: '5vw',
                                width: '55vw',
                                minHeight: '10vw',
                                padding: '3vw',
                                color: 'white',
                                zIndex: 1,
                                fontSize: '1.5vw',
                                opacity: 1,
                                transition: 'opacity 0s ease, transform 0s ease, height 0.3s ease',
                            }}
                        >
                            {displayedText}
                        </div>
                    </div>

                ) :
                (
                    <div style={{ position: 'relative', height: '300vw' }}>
                        <div
                            ref={windowRef}
                            style={{
                                top: '25vw',
                                position: 'sticky',
                                backgroundColor: 'rgba(25, 34, 39,0.5)',
                                borderRadius: '7vw',
                                width: '90vw',
                                minWidth: '90vw',
                                minHeight: '10vw',
                                paddingLeft: '4vw',
                                paddingRight: '4vw',
                                paddingTop: '2vw',
                                paddingBottom: '4vw',
                                color: 'white',
                                zIndex: 1,
                                fontSize: '5vw',
                                textAlign: 'justify',
                                opacity: 1,
                                transition: 'opacity 0s ease, transform 0s ease, height 0.3s ease',
                            }}
                        >
                            {displayedText}
                        </div>
                    </div>
                )}
        </>
    );
}
