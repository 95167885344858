import '../App.css';
import './ContentFirstScreen.css'
import { useFrame } from '@react-three/fiber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import './ContentFirstScreen.css'
import Marquee from 'react-fast-marquee';
import GetAccess from './GetAccess';
// import { useScroll } from '@react-three/drei';
import { throttle } from 'lodash';
import InfiniteMarquee from './InfiniteMarquee'

export default function ContentFirstScreen(props) {
    const contentRef = useRef();
    const title = useRef();
    const subtitle = useRef();
    const airplane = useRef();

    const [hide, setHide] = useState(false);


    const handleScroll = useCallback(() => {
        // console.log("call")
        if (props.windowWidth > 1060) {
            const scrollOffset = window.scrollY * 0.1;
            const scrollFactor = scrollOffset * 0.08;
            const baseScale = scrollFactor * 0.9 + 1;

            const newScale = Math.min(baseScale, 7);
            const finalX = Math.max(-scrollOffset * 2.4, -200);
            const finalY = Math.min(scrollOffset * 0.3, 25);

            if (contentRef.current) {
                contentRef.current.style.transform = `translate(${finalX}vw, ${finalY}vw) scale(${newScale})`;
            }

            const leftValue = Math.min(scrollOffset, 15);
            airplane.current.style.transform = `translateX(${leftValue}vw)`;
        } else {
            contentRef.current.style.transform = ``;
            const scrollOffset = window.scrollY * 0.1;

            const leftValue = Math.min(scrollOffset, 25);
            if (leftValue > -0.5)
                airplane.current.style.transform = `translateX(${leftValue}vw)`;
        }
    }, [props.windowWidth]);

    useEffect(() => {

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        !hide &&
        (
            props.windowWidth > 1060 ?
                (<div ref={contentRef} style={{ position: 'fixed', height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'transparent', pointerEvents: 'none', zIndex: 100, willChange: 'transform' }}>
                    <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: 'transparent', color: 'black', overflow: 'hidden', height: '100vh', pointerEvents: 'none', zIndex: 2, willChange: 'transform' }}>
                        <svg width="100%" height="100%" >
                            <mask id="mask2" >
                                <rect fill="white" width="100%" height="100%" />
                                <rect x="60%" y="17vh" width="33%" height="60vh" fill="black" />
                            </mask>
                            <rect mask="url(#mask2)" fill="#192227" width="100%" height="100%" />
                        </svg>
                    </div>

                    <div style={{ position: 'relative', height: '90vh', width: '90vw', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1vw', marginTop: '0vw', zIndex: 3, willChange: 'transform' }}>
                        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 5, willChange: 'transform' }}>
                            <div style={{ position: 'relative', display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%', gap: '1vw', height: '2rem' }}>
                                <div className='initial'>
                                    <span style={{ position: 'relative', animation: 'ease-in-out logoShow 1s', top: '0.3vw' }}>sfenders.</span>
                                </div>
                            </div>
                        </div>

                        <div style={{ position: 'relative', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 4, willChange: 'transform' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '65%', backgroundColor: 'white', color: 'black', borderTopLeftRadius: '2vw', borderBottomLeftRadius: '2vw', height: '100%', zIndex: 4 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '2.2vw', width: '100%', alignItems: 'start', justifyContent: 'center', overflow: 'hidden', height: '100%', paddingLeft: '5vw' }}>
                                    <div style={{ fontSize: '1vw', fontWeight: 500, display: 'flex', alignItems: 'center', opacity: 0.5 }}>
                                        <div style={{ paddingRight: '2vw' }}>RO</div>
                                        <div style={{ position: 'relative' }}>
                                            <FontAwesomeIcon ref={airplane} style={{ marginRight: '0.5vw', top: '0.2vw', position: 'absolute' }} icon={faPlane} />
                                            <span style={{ opacity: 0.4 }}>• • • • • • • • • • • • • • • • • • • •</span>
                                        </div>
                                        <div style={{ paddingLeft: '2vw' }}>UE</div>
                                    </div>
                                    <div ref={title} style={{ fontSize: '3.5vw', fontWeight: 500, maxWidth: '40vw', lineHeight: '7.5vh' }}>Descoperă Viitorul Licitațiilor Publice</div>
                                    <div ref={subtitle} style={{ fontSize: '1.5vw', fontWeight: 200, maxWidth: '45vw' }}>Transformă datele în timp real, analiza predictivă și rapoartele customizate în avantajul tău competitiv.</div>
                                    <div style={{ fontWeight: 200, maxWidth: '30vw', gap: '1rem', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        <div className="iconHover" style={{ borderRadius: '50%', height: '2vw', width: '2vw', overflow: 'hidden', backgroundImage: "url('ro.png')", backgroundSize: 'cover', backgroundPosition: 'center' }} />
                                        <div className="iconHover" style={{ borderRadius: '50%', height: '2vw', width: '2vw', overflow: 'hidden', backgroundImage: "url('ue.svg')", backgroundSize: 'cover', backgroundPosition: 'center' }} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '35%', backgroundColor: 'transparent', color: 'black', overflow: 'hidden', borderTopRightRadius: '2vw', borderBottomRightRadius: '2vw', height: '100%', pointerEvents: 'none', zIndex: 4 }}>
                                <div style={{ position: 'absolute', width: '2vw', backgroundColor: 'white', height: '100%', left: '64%' }} />
                                <svg width="100%" height="100%">
                                    <mask id="mask">
                                        <rect fill="white" width="100%" height="100%" />
                                        <circle cx="45%" cy="50%" r="35%" />
                                    </mask>
                                    <rect mask="url(#mask)" fill="white" width="100%" height="100%" />
                                </svg>
                            </div>
                        </div>

                        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20%', zIndex: 4, willChange: 'transform' }}>
                            <div style={{ position: 'relative', width: '65%', fontWeight: 500, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', borderRadius: '10vw' }}>
                                <div style={{ position: 'relative', fontSize: '1.5vw', width: '70%', fontWeight: 500, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: 'white', flexDirection: 'column' }}>
                                    <Marquee gradientColor="#192227" autoFill speed={30} gradient gradientWidth={100}>
                                        <span style={{ opacity: 0.3 }}>TED • SEAP •</span>
                                    </Marquee>
                                    <Marquee gradientColor="#192227" autoFill speed={60} gradient gradientWidth={100}>
                                        TED <span style={{ marginLeft: '0.4rem', marginRight: '0.4rem' }}>{'->'}</span> Tenders Electronic Daily • SEAP <span style={{ marginLeft: '0.4rem', marginRight: '0.4rem' }}>{'->'}</span> Sistemul Electronic de Achizitii Publice •
                                    </Marquee>
                                </div>
                            </div>
                            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '35%', height: '100%', zIndex: 5 }}>
                                <GetAccess fontSize={'2vw'} windowWidth={props.windowWidth} type={false} />
                            </div>
                        </div>
                    </div>
                </div>) :
                (<div ref={contentRef} style={{ position: 'absolute', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'transparent', pointerEvents: 'none', zIndex: 100, willChange: 'transform' }}>
                    <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: 'transparent', color: 'black', overflow: 'hidden', height: '100%', pointerEvents: 'none', zIndex: 2, willChange: 'transform' }}>
                        <svg width="100%" height="100%" >
                            <mask id="mask2" >
                                <rect fill="white" width="100%" height="100%" />
                                <circle cx="50%" cy="95%" r="30%" />
                            </mask>
                            <rect mask="url(#mask2)" fill="#192227" width="100%" height="100%" />
                        </svg>
                    </div>

                    <div style={{ position: 'relative', width: '90vw', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '5vw', marginTop: '0vw', zIndex: 3, willChange: 'transform', paddingTop: '10vw', paddingBottom: '80vw' }}>
                        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 5, willChange: 'transform' }}>
                            <div className='initial-phone'>
                                <span style={{ position: 'relative', animation: 'ease-in-out logoShow 1s', top: '0.3vw' }}>sfenders.</span>
                            </div>
                        </div>

                        <div style={{ position: 'relative', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 4, willChange: 'transform', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '6vw', width: '100%', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', height: '100%', paddingTop: '15vw', paddingBottom: '10vw', backgroundColor: 'white', borderRadius: '8vw' }}>
                                <div style={{ fontSize: '4vw', fontWeight: 500, display: 'flex', alignItems: 'center', opacity: 0.5 }}>
                                    <div style={{ paddingRight: '2vw' }}>RO</div>
                                    <div style={{ position: 'relative' }}>
                                        <FontAwesomeIcon ref={airplane} style={{ marginRight: '0.5vw', top: '0.8vw', position: 'absolute' }} icon={faPlane} />
                                        <span style={{ opacity: 0.4 }}>• • • • • • • • •</span>
                                    </div>
                                    <div style={{ paddingLeft: '2vw' }}>UE</div>
                                </div>
                                <div ref={title} style={{ fontSize: '12vw', fontWeight: 500, maxWidth: '70vw', lineHeight: '11vw', textAlign: 'center' }}>Descoperă Viitorul Licitațiilor Publice</div>
                                <div ref={subtitle} style={{ fontSize: '5vw', fontWeight: 200, maxWidth: '70vw', lineHeight: '6vw', textAlign: 'center' }}>Transformă datele în timp real, analiza predictivă și rapoartele customizate în avantajul tău competitiv.</div>
                                <div style={{ fontWeight: 200, maxWidth: '30vw', gap: '1rem', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                    <div className="iconHover" style={{ borderRadius: '50%', height: '10vw', width: '10vw', overflow: 'hidden', backgroundImage: "url('ro.png')", backgroundSize: 'cover', backgroundPosition: 'center' }} />
                                    <div className="iconHover" style={{ borderRadius: '50%', height: '10vw', width: '10vw', overflow: 'hidden', backgroundImage: "url('ue.svg')", backgroundSize: 'cover', backgroundPosition: 'center' }} />
                                </div>
                            </div>
                        </div>

                        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 4, willChange: 'transform', flexDirection: 'column', gap: '5vw' }}>
                            <div style={{ position: 'relative', fontSize: '4vw', width: '100%', fontWeight: 500, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: 'white', flexDirection: 'column' }}>
                                <Marquee gradientColor="#192227" autoFill speed={30} gradient gradientWidth={100}>
                                    <span style={{ opacity: 0.3 }}>TED • SEAP •</span>
                                </Marquee>
                                <Marquee gradientColor="#192227" autoFill speed={60} gradient gradientWidth={100}>
                                    TED <span style={{ marginLeft: '0.4rem', marginRight: '0.4rem' }}>{'->'}</span> Tenders Electronic Daily • SEAP <span style={{ marginLeft: '0.4rem', marginRight: '0.4rem' }}>{'->'}</span> Sistemul Electronic de Achizitii Publice •
                                </Marquee>
                            </div>
                            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '16vw', zIndex: 5 }}>
                                <GetAccess fontSize={'6vw'} windowWidth={props.windowWidth} type={false} />
                            </div>
                        </div>
                    </div>
                </div>)
        )
    );
}